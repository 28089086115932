import { Company } from '@agdir/domain';
import { CompanyService } from '@agdir/services';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
	standalone: true,
	selector: 'agdir-root',
	template: `
		<router-outlet />
	`,
	imports: [RouterOutlet],
})
export class AppComponent {
	private readonly companyService = inject(CompanyService);

	constructor() {
		this.companyService.setCurrentCompany({ id: '' } as Company);
	}
}
